@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("assets/Fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Barlow";
  src: local("Barlow"), url("assets/Fonts/Barlow-Medium.ttf") format("truetype");
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Poppins";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.checkmark-icon {
  vertical-align: middle !important;
  margin-right: 5px;
}

.swiper-pagination-bullet-active {
  background-color: rgb(250, 84, 28) !important;
}
